.sidebar {
  position: sticky;
  top: 0;
  left: 0;

  height: 100vh;
  padding: 2.4rem;

  display: flex;
  flex-direction: column;
  justify-content: center;

  box-shadow: 0 8px 14px rgba(0, 0, 0, 0.1);
  background-color: rgba(16, 25, 50, 0.9);
}

.sidebar-dark {
  background-color: rgba(0, 0, 0, 0.3);
}

@media (max-width: 1024px) {
  .sidebar {
    display: none;
  }
}

/* ---------------------------------------- */
/* sidebar name styling */
/* ---------------------------------------- */

.sidebar-name {
  position: absolute;
  top: 30px;
  left: 25px;

  display: flex;
  flex-direction: column;
  font-family: 'Roboto Slab', serif;
  color: #fff;
  font-size: 1.2rem;
  transition: color 0.2s ease-in-out;
}

.sidebar-name:hover {
  cursor: pointer;
  color: #eee;
}

/* ---------------------------------------- */
/* sidebar pagination styling */
/* ---------------------------------------- */

.sidebar ul {
  display: flex;
  flex-direction: column;
  width: 2.4rem;

  margin-bottom: 5rem;
}

.sidebar li {
  height: 20px;

  display: flex;
  align-items: center;
}

.sidebar li:hover {
  cursor: pointer;
}

.sidebar li:hover .pagination {
  width: 80%;
}

.sidebar li:hover .active {
  width: 100%;
}

.sidebar .pagination {
  background-color: rgba(255, 255, 255, 0.6);

  height: 2px;
  width: 70%;
  transition: width 0.2s ease;
}

.sidebar .pagination.active {
  width: 100%;
  background-color: rgba(255, 255, 255, 1);
}

/* ---------------------------------------- */
/* sidebar links styling */
/* ---------------------------------------- */

.sidebar-icons {
  position: absolute;
  bottom: 0;
  left: 40px;

  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.icon {
  transition: transform 0.2s ease;
  color: #fff;
}

.icon:hover {
  transform: scale(1.15);
}

.line {
  border-left: 1px solid #fff;
  height: 80px;
  left: 50%;
  transform: translateX(50%);
}

.line-dark {
  border-left: 1px solid #fff;
}
