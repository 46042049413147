.skills {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.skills-container {
  width: 70%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2.5rem;
}

@media (max-width: 767px) {
  .skills-container {
    width: 100%;
    margin-top: 0rem;
    padding: 1rem;

    gap: 2rem;
    justify-content: space-between;
  }
}

.skill-wrapper {
  text-align: center;
}

.skill-icon {
  background-color: rgba(255, 255, 255, 0.1);
  width: 64px;
  height: 64px;

  padding: 0.8rem;
  border-radius: 50%;
  margin-bottom: 0.7rem;

  box-shadow: 0 8px 14px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}

.skill-icon:hover {
  cursor: pointer;
  transform: scale(1.2);
}

.skill-label {
  font-size: 0.9rem;
  color: rgba(0, 0, 0, 0.6);
}

.skills-dark .skill-label {
  color: rgba(255, 255, 255, 0.5);
}

@media (max-width: 1024px) {
  .skill-icon {
    width: 56px;
    height: 56px;
  }

  .skill-label {
    font-size: 0.8rem;
  }
}

/* ---------------------------------- */
