.hero {
  position: relative;
  width: 100%;
  height: 100vh;

  display: flex;
  align-items: center;
  z-index: 1;
}

.hero-content {
  width: 700px;
  margin-left: 18%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media (max-width: 768px) {
  .hero-content {
    width: 100%;
    margin-left: 0;
    padding: 0 2rem;
  }
}

.start {
  transform: translateY(25%);
  opacity: 0;
}

.end {
  transform: translateY(0);
  opacity: 1;
}

/* ----------------------------------- */

.line-one,
.line-two {
  font-size: 3.5em;
  transition: opacity 0.4s ease-in-out;
  font-weight: 500;
}

.line-three {
  font-size: 2.5rem;
}

.hero-content h1,
.hero-content p {
  display: inline-block;
  transition: transform 0.3s ease-out;
}

@media (min-width: 1024px) {
  .hero-content h1:hover,
  .hero-content p:hover {
    transform: translateY(-5px);
  }
}

.btn-container {
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
}

/* ----------------------------------- */

.dynamic-line {
  margin-top: 1rem;
  margin-bottom: 2rem;
  font-size: 1.4rem;
  white-space: nowrap;
}

@media (max-width: 768px) {
  .line-one,
  .line-two {
    font-size: 2.2em;
  }

  .dynamic-line {
    font-size: 0.9rem;
  }
}

.typewriter-cursor {
  animation: blink-cursor 1s step-end infinite;
  margin-left: 2px;
}

@keyframes blink-cursor {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

/* ---------------------------------------- */
/* hero button styling */
/* ---------------------------------------- */

.resume-button {
  border: 1px solid #bdbdbd;
  background-color: transparent;
  color: #000;

  display: inline-block;
  position: relative;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;

  transition: transform 0.2s ease-out;
}

@media (max-width: 768px) {
  .resume-button {
    padding: 8px;
    font-size: 12px;
  }
}

.hero-dark .resume-button {
  color: #fff;
}

.resume-button::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: var(--highlight-color);
  transition: all 0.2s ease-in-out;
}

.resume-button:hover {
  transform: translateX(5px);
}

.hero-button:hover::after,
.resume-button:hover::after {
  width: 100%;
}
