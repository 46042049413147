.footer-container {
  background-color: transparent;
  color: #000;
  padding: 2rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-dark {
  color: #fff;
}

@media (max-width: 768px) {
  .footer-container {
    padding-top: 1rem;
  }

  .copyright {
    font-size: 0.9rem;
  }
}

/* -------------------------------- */
/* mobile icons styling */
/* -------------------------------- */

.mobile-icons {
  display: none;
  gap: 2rem;
  margin-bottom: 1rem;
}

.mobile-icon {
  color: #000;

  transition: transform 0.2s ease;
}

.footer-dark .mobile-icon {
  color: #fff;
}

.mobile-icon:hover {
  transform: scale(1.2);
}

@media (max-width: 1024px) {
  .mobile-icons {
    display: flex;
  }

  .mobile-icons i {
    font-size: 1.6rem;
  }
}
