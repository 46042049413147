.navbar-container {
  background-color: rgba(255, 255, 255, 0.8);

  position: fixed;
  top: 20px;
  right: 40px;

  border-radius: 30px;
  padding: 6px 20px 8px 20px;
  box-shadow: 0 8px 14px rgba(0, 0, 0, 0.1);

  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1.5rem;
  z-index: 5;
}

.navbar-container-dark {
  background-color: rgba(0, 0, 0, 0.5);
}

@media (max-width: 1024px) {
  .navbar-container {
    width: 100%;
    border: none;
    top: 0;
    right: 0;
    padding: 0;

    backdrop-filter: blur(0px);
  }
}

@media (max-width: 1024px) {
  .navbar {
    background-color: rgba(255, 255, 255, 0.9);
    position: fixed;
    top: 0;
    right: 0;

    width: 100%;
    height: 100vh;
    font-size: 1.1rem;

    transform: translateX(100%);
    transition: transform 0.2s ease-in-out;
    z-index: 10;
  }

  .nav-dark {
    background-color: rgba(0, 0, 0, 0.8);
  }

  .show-navbar {
    transform: translateX(0);
  }
}

/* ---------------------------------------- */
/* navbar styling */
/* ---------------------------------------- */

.navbar ul {
  font-size: 0.9rem;
  font-weight: 600;

  display: flex;
  align-items: center;
  gap: 1.5rem;
}

@media (max-width: 1024px) {
  .navbar ul {
    flex-direction: column;

    position: absolute;
    top: 160px;
    left: 60px;
    gap: 1.5rem;
  }
}

.navbar li {
  color: rgba(0, 0, 0, 0.8);
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.nav-dark li {
  color: rgba(255, 255, 255, 0.8);
}

.navbar li:hover,
.navbar li.active {
  cursor: pointer;
  color: #000;
}

.nav-dark li:hover,
.nav-dark li.active {
  color: #fff;
}

@media (max-width: 1024px) {
  .navbar li {
    color: #000;
  }

  .nav-dark li {
    color: #fff;
  }
}

.navbar li::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 0;
  height: 3px;
  background-color: var(--highlight-color);
  transition: all 0.2s ease-in-out;
}

.navbar li.active::after {
  width: 100%;
}

.nav-close-button {
  display: none;
}

@media (max-width: 1024px) {
  .nav-close-button {
    display: block;
    position: absolute;
    top: 12px;
    right: 12px;
  }
}
