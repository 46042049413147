.contact {
  position: relative;
  margin-bottom: 5rem !important;
}

@media (max-width: 768px) {
  .contact {
    margin-bottom: 2rem !important;
  }
}

/* ------------------------------------- */

.contact-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;

  transform: translateY(10%);
  opacity: 0.1;
  transition: opacity 0.6s ease-in-out, transform 0.6s ease-in-out;
}

@media (max-width: 1440px) {
  .contact-container {
    flex-direction: column;
    opacity: 1;
    transform: translateY(0);
  }
}

.contact-animate {
  opacity: 1;
  transform: translateY(0);
}

.contact-form {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 8px 14px rgba(0, 0, 0, 0.1);
  border-radius: 15px;

  width: 100%;
  height: 100%;
  width: 35rem;

  padding: 2rem;
  text-align: right;
}

@media (max-width: 768px) {
  .contact-form {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.3);
  }
}

.contact-dark .contact-form {
  background-color: rgba(0, 0, 0, 0.3);
  border: 1px solid #000;
}

.form-header {
  display: flex;
  flex-direction: column;

  gap: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
  text-align: center;
}

.contact-form label {
  font-size: 0.9rem;
  margin-bottom: 1rem;
  display: block;
  text-align: left;
}

.contact-form input,
.contact-form textarea {
  display: block;
  padding: 0.7rem;
  width: 100%;
  font-size: 1rem;
  margin-bottom: 1.6rem;
  border: 1px solid #bdbdbd;
  background-color: transparent;
  border-radius: 5px;
  resize: none;
}

.contact-dark .contact-form input,
.contact-dark .contact-form textarea {
  border: 1px solid rgba(255, 255, 255, 0.25);
  color: #f5f5f5;
}

.contact-form input:focus,
.contact-form textarea:focus {
  outline: none;
  border: 1px solid var(--highlight-color);
}

@media (max-width: 768px) {
  .contact-form button {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .contact-code {
    display: none;
  }
}

/* ------------------------------------- */
/* email send confirmation modal */
/* ------------------------------------- */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 20px;
  width: 500px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal-message {
  font-size: 18px;
  margin-bottom: 20px;
}

.modal button {
  margin-top: 5px;
  background-color: #fff;
  padding: 6px 20px;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .modal {
    width: 350px;
  }
}
