@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap');

:root {
  --gradient: linear-gradient(to bottom, #1a1a2d, #4759b0);
  --gradient1: linear-gradient(135deg, #d0b2e4 0%, #4751da 100%);
  --highlight-color: #14b8a6;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Raleway', 'Open Sans', sans-serif;
  width: 100%;
  height: 100%;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

li {
  list-style: none;
}

::selection {
  background-color: rgba(20, 184, 166, 0.4);
}

button:hover {
  cursor: pointer;
}

.btn {
  border: none;
  outline: none;
  background-color: var(--highlight-color);
  color: #fff;
  border-radius: 5px;
  padding: 0.7rem 2rem;
  transition: opacity 0.2s ease-in-out;
}

.btn:hover {
  opacity: 0.8;
}

/* ---------------------------------------- */

.App {
  display: flex;
}

.main-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.section {
  width: 70%;
  max-width: 1500px;
  margin: 0 auto;
  margin-bottom: 12rem;
}

@media (max-width: 1024px) {
  .section {
    width: 100%;
    padding: 0 0.5rem;
  }
}

@media (max-width: 768px) {
  .section {
    margin-bottom: 6rem;
  }
}

.section-header {
  margin-bottom: 4rem;
  display: flex;
  justify-content: center;

  opacity: 0;
  transform: scale(0);
}

.header-animate {
  animation: zoom-in 0.8s ease forwards;
}

@media (max-width: 1024px) {
  .section-header {
    opacity: 1;
    transform: scale(1);
    margin-bottom: 3rem;
  }

  .header-animate {
    animation: none;
  }
}

@media (max-width: 768px) {
  .section-header {
    margin-bottom: 2.5rem;
  }
}

@keyframes zoom-in {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  50% {
    transform: scale(110%);
  }

  100% {
    opacity: 1;
    transform: scale(100%);
  }
}

.section-header h1 {
  font-size: 1.4rem;
  padding-top: 2rem;

  letter-spacing: 3px;
  display: inline-block;
  position: relative;
}

.section-header h1::before {
  content: '';
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;

  border-top: 2px solid var(--highlight-color);
  transition: width 0.3s ease 0.3s;
}

.header-animate h1::before {
  width: 100%;
}

@media (max-width: 1024px) {
  .section-header h1 {
    font-size: 1.2rem;
  }
}

@media (max-width: 768px) {
  .section-header h1 {
    font-size: 1rem;
    padding-top: 0.8rem;
  }
}

/* ---------------------------------------- */
/* hovertree styling */
/* ---------------------------------------- */

#canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -10;
}

@media (max-width: 768px) {
  #canvas {
    display: none;
  }
}

/* ---------------------------------------- */
/* back-to-top-button styling */
/* ---------------------------------------- */

.back-to-top-button {
  position: fixed;
  bottom: 20px;
  right: 30px;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  border-radius: 50%;
  padding: 8px;
  font-size: 20px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  z-index: 999;
}
