.down-arrow {
  position: absolute;
  left: 50%;
  bottom: 0%;
  transform: translate(-50%, -50%);
}

@media (max-width: 768px) {
  .down-arrow {
    bottom: 1%;
  }
}

.down-arrow:after {
  position: absolute;
  content: '\02193';
  color: #000;
  font-size: 40px;
  top: 50%;
  left: 50%;
  /* transform: translate(-50%, -55%); */
  animation: arrow-bounce 0.5s infinite alternate ease-out;
}

.down-arrow-dark:after {
  color: #fff;
}

@keyframes arrow-bounce {
  0% {
    transform: translate(-50%, calc(-55% + 2px));
  }
  100% {
    transform: translate(-50%, calc(-55% - 4px));
  }
}

.circle {
  height: 124px;
  width: 124px;
}

.background {
  fill: transparent;
}

.foreground {
  fill: transparent;
  stroke-dasharray: 377;
  stroke-dashoffset: 377;
  stroke: rgba(0, 0, 0, 0.5);
  transform-origin: 50% 50%;
  transform: rotate(-90deg);
  transition: stroke-dashoffset 800ms ease, transform 800ms ease;
}

.down-arrow-dark .foreground {
  stroke: #fff;
}

a:hover .foreground {
  stroke-dashoffset: 0;
  transform: rotate(90deg);
}
