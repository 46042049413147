/* ---------------------------------------- */
/* nav-toggles styling */
/* ---------------------------------------- */

.nav-toggles {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  z-index: 5;
}

.nav-menu-toggle {
  display: none;
}

@media (max-width: 1024px) {
  .nav-toggles {
    position: fixed;
    top: 20px;
    right: 20px;
  }

  .nav-menu-toggle {
    display: block;
  }
}

/* ---------------------------------------- */
/* theme-toggle styling */
/* ---------------------------------------- */

.theme-toggle {
  transform: scale(1);
  transition: transform 0.2s ease;
  user-select: none;
}

.theme-toggle:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.theme-toggle:active {
  transform: scale(0.7);
  transition: transform 0.2s ease;
}

@media (max-width: 1024px) {
  .theme-toggle {
    transform: scale(0.8);
  }

  .theme-toggle:hover {
    transform: scale(0.8);
  }
}

.lightMode-spin {
  animation: clockwise-spin 0.4s ease;
}

@keyframes clockwise-spin {
  0% {
    transform: rotate(-90deg);
  }

  25% {
    transform: rotate(45deg);
  }

  50% {
    transform: rotate(0deg);
  }

  75% {
    transform: rotate(15deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.darkMode-spin {
  animation: counter-clockwise-spin 0.4s ease;
}

@keyframes counter-clockwise-spin {
  0% {
    transform: rotate(90deg);
  }

  50% {
    transform: rotate(-25deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.lightMode-spin:active,
.darkMode-spin:active {
  animation: spin 0.2s ease;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
