.about {
  margin-top: 2rem;
}

/* ------------------------------------- */

.about-content {
  width: 100%;
  border-radius: 15px;

  display: flex;
  justify-content: center;
  gap: 2rem;

  opacity: 0.1;
  transform: translateY(10%);
  transition: opacity 0.6s ease-in-out, transform 0.6s ease-in-out;
}

@media (max-width: 1024px) {
  .about-content {
    opacity: 1;
    transform: translateY(0);
  }
}

.content-animate {
  opacity: 1;
  transform: translateY(0);
}

.content-left {
  background-color: #f7f7f7;

  min-width: 500px;
  flex: 1;

  padding: 3rem;
  box-shadow: 0 8px 14px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
}

.content-right {
  flex: 1;
}

.about-dark .content-left {
  background-color: rgba(0, 0, 0, 0.3);
}

@media (max-width: 1440px) {
  .content-right {
    width: 50%;
  }
}

@media (max-width: 1024px) {
  .about-content {
    padding: 1rem;
    flex-direction: column;
  }

  .content-left,
  .content-right {
    width: 100%;
  }

  .content-left {
    min-width: 100%;
  }
}

@media (max-width: 768px) {
  .about-content {
    padding: 0.5rem;
  }

  .content-left {
    padding: 2rem 1rem;
  }
}

/* ------------------------------------- */

.about h3 {
  font-size: 1.4rem;
  margin-bottom: 2rem;
}

.about p {
  color: rgba(0, 0, 0, 0.7);
  font-size: 1rem;
  line-height: 1.7;
  margin-bottom: 1.5rem;
}

@media (max-width: 768px) {
  .about p {
    color: rgba(0, 0, 0, 0.8);
    font-size: 0.9rem;
    line-height: 1.5;
  }
}

.about-dark p {
  color: rgba(255, 255, 255, 0.7);
}

.about .highlight {
  color: var(--highlight-color);
}
