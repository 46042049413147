.projects {
  position: relative;
}

/* ------------------------------------- */

.projects-group {
  width: 100%;
  margin-bottom: 3rem;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2.5rem;
}

@media (max-width: 768px) {
  .projects-group {
    margin-bottom: 0;
    gap: 0;
  }
}

.project {
  background-color: #f7f7f7;
  box-shadow: 0 8px 14px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 1.5rem;

  min-width: 300px;

  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  gap: 1rem;

  transform: translateX(-25%);
  opacity: 0;
  transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
}

.projects-dark .project {
  background-color: rgba(255, 255, 255, 0.1);
}

.project-animate {
  transform: translateX(0);
  opacity: 1;
}

@media (max-width: 1024px) {
  .project {
    width: 100%;
    transform: translateX(-20%);
    opacity: 0;
    margin-bottom: 2rem;
  }

  .project-animate {
    transform: translateX(0);
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .project {
    flex: none;
  }
}

.project-image {
  overflow: hidden;
}

.project-image img {
  width: 100%;
  box-shadow: 0 8px 14px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.project-image:hover img {
  transform: scale(1.2);
}

.project-content {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.project-content h3 {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.project-content p {
  margin-bottom: 3rem;
  line-height: 1.7;
  font-size: 0.9rem;
}

@media (max-width: 1024px) {
  .project-content {
    align-items: center;
  }

  .project-content h3 {
    font-size: 1.1rem;
    margin-bottom: 1rem;
  }

  .project-content p {
    font-size: 0.7rem;
    margin-bottom: 2rem;
  }
}

/* ------------------------------------- */

.button-group {
  position: absolute;
  bottom: 20px;
  display: flex;
  gap: 10px;
}

@media (max-width: 1024px) {
  .button-group {
    position: relative;
    bottom: 0px;
  }
}

.button-group .btn {
  padding: 6px 12px;
  font-size: 0.9rem;

  box-shadow: 0 8px 14px rgba(0, 0, 0, 0.1);
  border-radius: 15px;

  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
}

.button-group .btn:hover {
  opacity: 0.8;
  transform: translateY(-5%);
}

.button-group .btn .icon-container {
  margin-left: 6px;
}

@media (max-width: 1024px) {
  .button-group .btn {
    font-size: 0.9rem;
  }
}
